import React from 'react';
import { Switch, Route } from 'react-router-dom';
import a11 from '../pages/A11/A11';
import a12 from '../pages/A12/A12';
import a13 from '../pages/A13/A13';
import a21 from '../pages/A21/A21';
import a22 from '../pages/A22/A22';
import a23 from '../pages/A23/A23';
import b11 from '../pages/B11/B11';
import b12 from '../pages/B12/B12';
import b13 from '../pages/B13/B13';
import b14 from '../pages/B14/B14';
import b21 from '../pages/B21/B21';
import b22 from '../pages/B22/B22';
import b23 from '../pages/B23/B23';
import b24 from '../pages/B24/B24';




// import l1work from '../pages/L1Work/L1work';
// import l2work from '../pages/L2work/L2work';
// import lesson4 from '../pages/B11U1L4/B11U1L4';
// import U1test from '../pages/U1Test/U1Test';
// import Videos from '../pages/Videos/Videos';


export default function Routes() {

 return(

    <Switch>
      {/* <Route path='/Repositories' component={Repositories} /> */}
      <Route path='/a11' component={a11} />
      <Route path='/a12' component={a12} />
      <Route path='/a13' component={a13} />
      <Route path='/a21' component={a21} />
      <Route path='/a22' component={a22} />
      <Route path='/a23' component={a23} />
      <Route path='/b11' component={b11} />
      <Route path='/b12' component={b12} />
      <Route path='/b13' component={b13} />
      <Route path='/b14' component={b14} />
      <Route path='/b21' component={b21} />
      <Route path='/b22' component={b22} />
      <Route path='/b23' component={b23} />
      <Route path='/b24' component={b24} />

      
      {/* <Route path='/l1work' component={l1work} /> 
      <Route path='/l2work' component={l2work} /> 
      <Route path='/lesson4' component={lesson4} />
      <Route path='/U1test' component={U1test} />
      <Route path='/Videos' component={Videos} />
     */}
    </Switch>
 );

}

